@import url('https://fonts.googleapis.com/css?family=Space+Mono&display=swap');

body {
  font-size: 18px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  font-kerning: normal;
  line-height: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Space Mono', monospace;
  color: #ff9200;
}

p {
  line-height: 1.618em;
}

.question {
  color: #ff2f77;
  font-weight: 700;
  font-size: 1.2rem;
}

.response {
  color: #aaa;
}

a,
button,
input[type="submit"],
.highlight {
  font-size: 1.2rem;
}

h1 {
  font-weight: bold;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 720px) {
  h1 {
    font-size: 2.7rem;
  }
}

h2 {
  font-weight: bold;
  font-size: 1.2rem;
}