html, body {
  display: flex;
  flex: 1;
  height:100%;
  flex-direction: column;
  background: #1c1c1c;
}

.lock-scroll {
  overflow: hidden !important;
}

#root {
  background: #000;
  flex: 1;
}

main {
  padding: 4rem 3rem;
  width: 50vw;
  max-width: 540px;
}

.game-appear {
  min-height: 0;
  max-height: 0;
  background: #1c1c1c;
}

.game,
.game-appear-done {
  background: #1c1c1c;
  max-height: 100%;
  min-height: 100%;
  transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
  -webkit-transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
  -moz-transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
  -ms-transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
  -o-transition: max-height 600ms ease-in-out, min-height 600ms ease-in-out;
}

.progress {
  position: fixed;
  left: 0;
  top: 0;
  width: 3px;
  background: #ff2f77;
  transition: height 150ms ease-in;
  -webkit-transition: height 150ms ease-in;
  -moz-transition: height 150ms ease-in;
  -ms-transition: height 150ms ease-in;
  -o-transition: height 150ms ease-in;
}

a {
  display: inline-block;
}

.response {
  margin-bottom: 0;
}

.highlight,
a:not(.social-media-link),
input[type="submit"],
button:not(.restart-button) {
  line-height: 1.15em;
  padding: 0;
  margin: 0;
  background-image: linear-gradient(to right, #ff2f77 50%, #1c1c1c 50%);
  background-size: 200% 100%;
  background-position: 100% 100%;
  border: none;
  outline: none;
  text-decoration: none;
  color: #FF2F77;
  text-align: left;
  transition: background-position 300ms, color 300ms;
  -webkit-transition: background-position 300ms, color 300ms;
  -moz-transition: background-position 300ms, color 300ms;
  -ms-transition: background-position 300ms, color 300ms;
  -o-transition: background-position 300ms, color 300ms;
}

input[type="submit"]:disabled {
  display: none;
}

.highlight,
.option-selected button,
a:hover,
a:not(.social-media-link):focus,
button:not(.restart-button):hover,
button:not(.restart-button):focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
  display: inline-block;
  background-position: 0% 100%;
  color: #1c1c1c;
}

.restart-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5rem;
  bottom: 1.5rem;
  padding: .5em;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  color: #FF2F77;
  background: rgba(28, 28, 28, 0.7);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  transition: transform 300ms;
  border: 2px solid transparent;
  -webkit-transition: transform 300ms;
  -moz-transition: transform 300ms;
  -ms-transition: transform 300ms;
  -o-transition: transform 300ms;
}

.restart-button:focus,
.restart-button:hover {
  border: 2px solid #FF2F77;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.restart-dialogue-shadow {
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restart-dialogue {
  padding: 1em;
  background-color: #1c1c1c;
  z-index: 2;
  margin: 0 auto;
  max-width: 540px;
  border: 1px solid #ff9200;
  left: 0;
  right: 0;
  box-shadow: rgba(0,0,0,0.5) 5px 5px 20px;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
}

.restart-dialogue p {
  margin-top: 0;
}

.restart-dialogue button {
  margin-right: 1rem;
}

.social-media-container {
  margin-top: 2rem;
}

.social-media-link {
  color: #FF2F77;
  margin: 0 1rem 0 0;
  transition: color 500ms;
  -webkit-transition: color 500ms;
  -moz-transition: color 500ms;
  -ms-transition: color 500ms;
  -o-transition: color 500ms;
}

.social-media-link:hover,
.social-media-link:focus {
  color: #fff;
  outline: 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-left: 1rem;
}

li:not(:last-child) {
  margin-bottom: 1rem;
  max-height: 90px;
}

input[type="text"] {
  color: #FF2F77;
  width: 270px;
  border: none;
  border-bottom: 1px solid #FF2F77;
  background: none;
  outline: none;
  margin-bottom: 1rem;
}

.glitch {
  animation: glitch-skew 5s cubic-bezier(.25, .46, .45, .94) infinite;
  -webkit-animation: glitch-skew 5s cubic-bezier(.25, .46, .45, .94) infinite;
}

.glitch-accent {
  z-index: -1;
  color: #FF2F77;
  position: absolute;
  left: 0;
  top: 0;
  content: "Interactive Story: Emet Robotics";
  animation: glitch 5s cubic-bezier(.25, .46, .45, .94) infinite;
  -webkit-animation: glitch 5s cubic-bezier(.25, .46, .45, .94) infinite;
}

.mobile-ascii-image {
  display: none;
}

.desktop-chapter-images {
  position: fixed;
  top: 3rem;
  right: 54vw;
}

.desktop-ascii-image {
  position: absolute;
  max-width: 50vw !important;
  max-height: 85vh !important;
}

@media (max-width: 1350px) {
  .desktop-chapter-images {
    position: fixed;
    top: 3rem;
    right: 48vw;
  }

  .desktop-ascii-image {
    max-width: 45vw !important;
  }
}

@media (max-width: 1200px) {
  .desktop-chapter-images {
    right: 43vw;
    top: 2rem;
  }

  .desktop-ascii-image {
    max-width: 40vw !important;
  }
}

@media (max-width: 900px) {
  .desktop-ascii-image {
    display: none !important;
  }

  .mobile-ascii-image {
    display: block;
    width: 100%;
    margin-top: 1.5rem;
  }

  main {
    width: auto;
    max-width: 620px;
  }

  li {
    margin-left: 0;
  }
}

@media (max-width: 720px) {
  main {
    padding: 2rem 1.5rem 6rem 2rem;
  }

  a:not(.social-media-link),
  button:not(.restart-button),
  input[type="submit"] {
    background-position: 0% 100%;
    color: #1c1c1c;
  }
}

.image-enter {
  opacity: 0;
}

.image-exit-done {
  opacity: 0;
  transition: opacity 400ms ease-in-out 400ms;
  -webkit-transition: opacity 400ms ease-in-out 400ms;
  -moz-transition: opacity 400ms ease-in-out 400ms;
  -ms-transition: opacity 400ms ease-in-out 400ms;
  -o-transition: opacity 400ms ease-in-out 400ms;

}

.image-enter-done {
  opacity: 1;
  transition: opacity 400ms ease-in-out 400ms;
  -webkit-transition: opacity 400ms ease-in-out 400ms;
  -moz-transition: opacity 400ms ease-in-out 400ms;
  -ms-transition: opacity 400ms ease-in-out 400ms;
  -o-transition: opacity 400ms ease-in-out 400ms;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  padding-top: 1rem;
}

.fade-enter-done,
.fade-appear-done {
  opacity: 1;
  padding-top: 0.01rem;
  transition: opacity 400ms ease-in-out 400ms, padding 400ms ease-in-out 400ms;
  -webkit-transition: opacity 400ms ease-in-out 400ms, padding 400ms ease-in-out 400ms;
  -moz-transition: opacity 400ms ease-in-out 400ms, padding 400ms ease-in-out 400ms;
  -ms-transition: opacity 400ms ease-in-out 400ms, padding 400ms ease-in-out 400ms;
  -o-transition: opacity 400ms ease-in-out 400ms, padding 400ms ease-in-out 400ms;
}

li.option-exit,
li.option-exit-done {
  margin-bottom: 0;
  transition: margin-bottom 150ms 150ms;
  -webkit-transition: margin-bottom 150ms 150ms;
  -moz-transition: margin-bottom 150ms 150ms;
  -ms-transition: margin-bottom 150ms 150ms;
  -o-transition: margin-bottom 150ms 150ms;
}

li.option-exit:not(.option-selected),
li.option-exit-done:not(.option-selected) {
  max-height: 0;
  margin-bottom: 0;
  transition: max-height 150ms 150ms, margin-bottom 150ms 150ms;
  -webkit-transition: max-height 150ms 150ms, margin-bottom 150ms 150ms;
  -moz-transition: max-height 150ms 150ms, margin-bottom 150ms 150ms;
  -ms-transition: max-height 150ms 150ms, margin-bottom 150ms 150ms;
  -o-transition: max-height 150ms 150ms, margin-bottom 150ms 150ms;
}

li.option-exit:not(.option-selected) button,
li.option-exit-done:not(.option-selected) button {
  background-position: 100% 100%;
  color: #1c1c1c;
  transition: background-position 150ms, color 150ms;
  -webkit-transition: background-position 150ms, color 150ms;
  -moz-transition: background-position 150ms, color 150ms;
  -ms-transition: background-position 150ms, color 150ms;
  -o-transition: background-position 150ms, color 150ms;
}

.option-selected ~ li:not(.option-exit-done):not(.option-exit),
li.option-exit-done:not(.option-selected) {
  display: none;
}

@keyframes glitch-skew {
  95% {
    transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  96% {
    transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    -moz-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
  }
  97% {
    transform: skew(-65deg, 0deg);
    -webkit-transform: skew(-65deg, 0deg);
    -moz-transform: skew(-65deg, 0deg);
    -ms-transform: skew(-65deg, 0deg);
    -o-transform: skew(-65deg, 0deg);
  }
  98% {
    transform: skew(30deg, 0deg);
    -webkit-transform: skew(30deg, 0deg);
    -moz-transform: skew(30deg, 0deg);
    -ms-transform: skew(30deg, 0deg);
    -o-transform: skew(30deg, 0deg);
  }
  100% {
    transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
  }
}

@keyframes glitch-skew-mobile {
  95% {
    transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  96% {
    transform: skew(5deg, 0deg);
    -webkit-transform: skew(5deg, 0deg);
    -moz-transform: skew(5deg, 0deg);
    -ms-transform: skew(5deg, 0deg);
    -o-transform: skew(5deg, 0deg);
}
  97% {
    transform: skew(-60deg, 0deg);
    -webkit-transform: skew(-60deg, 0deg);
    -moz-transform: skew(-60deg, 0deg);
    -ms-transform: skew(-60deg, 0deg);
    -o-transform: skew(-60deg, 0deg);
}
  98% {
    transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    -moz-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
}
  100% {
    transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    -moz-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
  }
}

@keyframes glitch {
  95% {
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
  96% {
    transform: translate(-3px, 3px);
    -webkit-transform: translate(-3px, 3px);
    -moz-transform: translate(-3px, 3px);
    -ms-transform: translate(-3px, 3px);
    -o-transform: translate(-3px, 3px);
  }
  97% {
    transform: translate(-3px, -3px);
    -webkit-transform: translate(-3px, -3px);
    -moz-transform: translate(-3px, -3px);
    -ms-transform: translate(-3px, -3px);
    -o-transform: translate(-3px, -3px);
  }
  98% {
    transform: translate(3px, 3px);
    -webkit-transform: translate(3px, 3px);
    -moz-transform: translate(3px, 3px);
    -ms-transform: translate(3px, 3px);
    -o-transform: translate(3px, 3px);
  }
  99% {
    transform: translate(2px, -2px);
    -webkit-transform: translate(2px, -2px);
    -moz-transform: translate(2px, -2px);
    -ms-transform: translate(2px, -2px);
    -o-transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
  }
}
